<template>
  <el-row id="checkout">
      <el-col :span="12" class="checkout-sub-div right-line">
        <div><el-image fit="fill" class="logo-img-2" :src="require('../../assets/ushop3.png')" style="cursor:pointer;" @click="homePageClick"></el-image></div>
        <el-breadcrumb separator-class="el-icon-arrow-right" style="padding:0px 5px;">
            <el-breadcrumb-item to="/cart">Cart</el-breadcrumb-item>
            <el-breadcrumb-item>Infomation</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPayment > 0">Payment</el-breadcrumb-item>
            <el-breadcrumb-item v-if="showPayment > 1">Scan To Pay</el-breadcrumb-item>
        </el-breadcrumb>
        <div v-if="showPayment == 0" id="checkout_infomation">
            <div style="font-size:18px;">Contact Infomation</div>
            <div style="margin-top:20px;">
                <el-input v-model="contactName" placeholder="please input contact name">
                </el-input>
            </div>
            <div style="margin-top:20px;">
                <el-input v-model="contactNumber" placeholder="please input contact name">
                </el-input>
            </div>
            <div style="font-size:18px;margin-top:40px;">Shipping Address</div>
            <div style="margin-top:20px;">
                <el-select v-model="expectedLocation" placeholder="choose location">
                    <el-option v-for="item in selectLocations" :key="item.ID" :label="item.name" :value="item.ID"></el-option>
                </el-select>
                <div style="margin-top:10px;color:grey;font-size:12px;">{{expectedLocationAddress}}</div>
            </div>
            <div style="font-size:18px;margin-top:40px;">Shipping Method</div>
            <div style="margin-top:20px;">
                <div class="checkout-shipping-method-top">
                    <div style="-webkit-box-flex:1;"><el-radio v-model="shippingMethod" label="free">Free Shipping</el-radio></div>
                    <div><span style="color:red;text-decoration:line-through;display:float;margin-right:10px;">$6.90</span><span>$0.00</span></div>
                </div>
                <!--<div class="checkout-shipping-method-bottom">
                    <div style="-webkit-box-flex:1;"><el-radio v-model="shippingMethod" label="standard">Standard Shipping</el-radio></div>
                    <div>$8.00</div>
                </div>-->
            </div>
            <div style="margin-top:20px;display:-webkit-box;">
                <div><el-button type="danger" @click="gotoPaymentClick">Continue to payment</el-button></div>
                <div style="margin-left:20px;line-height:40px;"><el-link href="/cart" :underline="false">Return to cart</el-link></div>
            </div>
        </div>
        <div v-if="showPayment == 1" id="checkout_payment">
            <div style="border:1px solid #DCDFE6;border-radius:5px;">
                <div class="checkout-payment-info-column">
                    <div style="width:120px;color:#737373;">Contact</div>
                    <div>
                        <div>{{contactName}}</div>
                        <div style="margin-top:5px;color:grey;font-size:12px;">{{contactNumber}}</div>
                    </div>
                </div>
                <div class="checkout-payment-info-column">
                    <div style="width:120px;color:#737373;">Ship to</div>
                    <div>
                        <div>{{expectedLocationName}}</div>
                        <div style="margin-top:5px;color:grey;font-size:12px;">{{expectedLocationAddress}}</div>
                    </div>
                </div>
                <div class="checkout-payment-info-column-2">
                    <div style="width:120px;color:#737373;">Method</div>
                    <div>Free Shipping Disccount</div>
                </div>
            </div>
            <div style="font-size:18px;margin-top:40px;">Payment</div>
            <div style="margin-top:20px;">
                <div class="checkout-payment-method-top">
                    <div style="-webkit-box-flex:1;line-height:32px;"><el-radio v-model="paymentMethod" label="sgqr">SGQR</el-radio></div>
                    <div><el-image style="height:32px;width:48px;" fit="fit" :src="require('../../assets/SGQR.jpg')"></el-image></div>
                </div>
            </div>
            <div style="margin-top:20px;display:-webkit-box;">
                <div><el-button type="danger" :loading="paynowLoading" @click="paynowClick">Pay now</el-button></div>
                <div style="margin-left:20px;line-height:40px;"><el-link :underline="false" @click="showPayment = 0">Return to infomation</el-link></div>
            </div>
        </div>
        <div v-if="showPayment == 2" id="checkout_pay_code">
            <div style="text-align:center;"><el-image fit="fit" style="height:48px;width:72px;" :src="require('../../assets/SGQR.jpg')"></el-image></div>
            <div style="text-align:center;"> <vue-qr :text="payRequest.qrCode" :size="300"></vue-qr></div>
            <div style="background-color:#EE1B3B;color:#fff;text-align:center;padding:15px 20px;width:223px;margin:0 auto;border-radius:5px;font-weight:bold;">SCAN TO PAY</div>
            <div style="text-align:center;margin-top:20px;"><el-image style="width:100%;" :src="require('../../assets/banks.png')" fit="fit"></el-image></div>
            <div class="anouncement-div-3"><i class="el-icon-loading"></i>  Payment Pending...Please do not close or leave this webpage before your payment is confirmed.</div>
            <div style="text-align:center;margin-top:20px;"><el-link :underline="false" @click="backToPaynow">Return to payment</el-link></div>
        </div>
        <div class="chdkout-sub-div-bottom">
        </div>
        <div>
            <el-divider></el-divider>
            <div style="display:-webkit-box;">
                <div style="margin-right:10px;"><el-link href="/pages/privacy-policy">Privacy Policy</el-link></div>
                <div><el-link href="/pages/terms-of-service">Terms of Service</el-link></div>
            </div>
        </div>
      </el-col>
      <el-col :span="12" class="checkout-sub-div-2">
          <div class="anouncement-div-2" v-if="showAnounce">Order paid after 5:00PM will take extra one day deliver delay.</div>
          <checkout-deliver-item v-for="item in goodListByDeliverDate" :key="item.key" :items="item" @onDateChange="onDateChange" :showChange="showPayment == 0"></checkout-deliver-item>
          <div style="display:-webkit-box;font-size:14px;">
              <div>Subtotal</div>
              <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                  <span v-if="isMember">${{cartMemberTotal}}</span>
                  <span v-else>${{cartTotal}}</span>
              </div>
          </div>
          <div style="display:-webkit-box;margin-top:10px;font-size:14px;">
              <div>Shipping</div>
              <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:14px;">
                  <span v-if="shippingMethod == 'free'"><span style="color:red;text-decoration:line-through;display:float;margin-right:10px;">$6.90</span><span>$0.00</span></span>
                  <span v-if="shippingMethod == 'standard'">$6.90</span>
              </div>
          </div>
          <el-divider></el-divider>
          <div style="display:-webkit-box;">
              <div>Total</div>
              <div style="-webkit-box-flex:1;text-align:right;font-weight:bold;font-size:24px;">
                  <span v-if="isMember">${{cartMemberTotal}}</span>
                  <span v-else>${{cartTotal}}</span>
              </div>
          </div>
      </el-col>
  </el-row>
</template>

<script>
import ModelCheckout from '../viewmodel/ModelCheckout.vue'
import CheckoutDeliverItem from '../../components/widgets/CheckoutDeliverItem.vue'
import VueQr from 'vue-qr'

export default {
    extends: ModelCheckout,
    name: 'checkout',
    components: {
        CheckoutDeliverItem,
        VueQr
    }
}
</script>

<style>
</style>